import React, { useState, useEffect } from "react";
import { Container, Grid, Typography, Box, Button } from "@mui/material";
// import axios from 'axios';
// import BgImage from '../../../public/assets/Bg-image.jpg';
// import Footer from '../../components/Footer/footer';
import "@fortawesome/fontawesome-free/css/all.min.css";
import { useNavigate, useParams } from "react-router-dom";
import "./admin.css";

export default function TransactionDeposits() {
  // const [deposits, setDeposits] = useState([]);

  // useEffect(() => {
  //   axios.get('https://lukzbetbackend.gamingesupport.xyz/api/Get_Admin_Deposit_Data', {
  //     headers: {
  //       'Authorization': `Bearer ${localStorage.getItem('token')}`
  //     }
  //   })
  //   .then(response => {
  //     // Filter the deposits to include only those with status 'approved'

  //     const approvedDeposits = response.data.data;
  //     setDeposits(approvedDeposits);
  //   })
  //   .catch(error => {
  //     console.error('Error fetching deposits:', error.response ? error.response.data : error.message);
  //   });
  // }, []);

  // const formatDate = (dateString) => {
  //   const date = new Date(dateString);
  //   return date.toISOString().split('T')[0]; // Returns YYYY-MM-DD
  // };

  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
        }}
      >
        (
        <Box
          sx={{
            width: "70%",
            borderRadius: "30px",
            marginTop: "20px",
            marginBottom: "20px",
            padding: "20px",
            backgroundColor: "rgba(102,255,102,0.5)",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" sx={{ color: "black", fontWeight: "bold" }}>
            Deposit
          </Typography>
          <Box>
            <Typography
              variant="h6"
              sx={{ color: "black", fontWeight: "bold", textAlign: "center" }}
            >
              Amount
            </Typography>
            <Typography
              variant="h5"
              sx={{ color: "black", fontWeight: "bold", textAlign: "center" }}
            >
              LKR 100
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="h6"
              sx={{ color: "black", fontWeight: "bold", textAlign: "center" }}
            >
              Reference No :
            </Typography>
            <Typography
              variant="h6"
              sx={{ color: "black", fontWeight: "bold", textAlign: "center" }}
            >
              jjii9911llmmnn
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="h6"
              sx={{ color: "black", fontWeight: "bold", textAlign: "center" }}
            >
              Date
            </Typography>
            <Typography
              variant="h6"
              sx={{ color: "black", fontWeight: "bold", textAlign: "center" }}
            >
              09/01/2024
            </Typography>
          </Box>
        </Box>
        )
      </Box>
    </Box>
  );
}
