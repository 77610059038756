import React, { useState, useEffect } from "react";
import { Avatar, Box, Button, TextField, Typography } from "@mui/material";
import upload from "../images/upload.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import keta from "../images/Keta.jpg";
import keta1 from "../images/keta1.jpg";
import axios from "axios";
import Swal from "sweetalert2";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("05", 159, 6.0, 24, 4.0),
  createData("04", 237, 9.0, 37, 4.3),
  createData("03", 262, 16.0, 24, 6.0),
  createData("02", 305, 3.7, 67, 4.3),
  createData("01", 356, 16.0, 49, 3.9),
];

export default function Deposit() {
  const [image, setImage] = useState(null);
  const [amount, setAmount] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const token = localStorage.getItem("auth_token");
  const [preview, setPreview] = useState(null);
  const [bankDetails, setBankDetails] = useState([]);

  // Handle image upload

  // Function to trigger the file input when clicking the avatar
  const handleAvatarClick = () => {
    document.getElementById("fileInput").click();
  };

  const popup = () => {
    Swal.fire({
      title: "Are you sure?",

      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "green",
      confirmButtonText: "Deposit",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          "Deleted!",
          "Your imaginary file has been deleted.",
          "success"
        );
        handleSubmit(); // This now works without needing an event
      }
    });
  };

  useEffect(() => {
    // Fetch bank details from the API
    axios
      .get("https://lukzbetbackend.gamingesupport.xyz/api/show-bank-details", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Include token if required
        },
      })
      .then((response) => {
        setBankDetails(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching bank details:", error);
        setLoading(false);
      });
  }, []);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setImage(file); // Save selected image file
    if (file) {
      // Convert file to a URL to display in Avatar
      const imageUrl = URL.createObjectURL(file);
      setPreview(imageUrl);
      setImage(file);
    }
  };

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
    setLoading(true);

    // Prepare form data
    const formData = new FormData();
    formData.append("deposit_amount", amount);
    formData.append("transaction_id", transactionId);
    formData.append("split_image", image);

    try {
      const response = await axios.post(
        "https://lukzbetbackend.gamingesupport.xyz/api/deposit",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`, // Replace with the actual token if needed
          },
        }
      );
    } catch (error) {}
  };

  const [depositDetails, setDepositDetails] = useState();

  useEffect(() => {
    const fetchDepositDetails = async () => {
      try {
        const response = await axios.get(
          "https://lukzbetbackend.gamingesupport.xyz/api/user-deposit-details",
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token if needed
            },
          }
        );
        setDepositDetails(response.data); // Assuming the API returns an array
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching deposit details:", error);
        setDepositDetails([]); // Set to empty array if there's an error
      }
    };

    fetchDepositDetails();
  }, [token]); // Run when the component mounts or when the token changes

  return (
    <Box
      sx={{
        width: "100%",
        height: {
          xs: "200vh",
          sm: "180vh",
          md: "200vh",
          lg: "100vh",
          xl: "100vh",
        },
        backgroundColor: "#0C314D",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "95%",
          height: "95vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: { md: "#0C314D", lg: "#103958", xl: "#103958" },
          borderRadius: "10px",
          flexDirection: { xs: "column", md: "column", lg: "row", xl: "row" },
        }}
      >
        <Box sx={{ width: "100%", height: "100%" }}>
          <Box
            sx={{
              width: "90%",
              height: { xs: "40%", sm: "50%", md: "50%", lg: "50%", xl: "50%" },
              backgroundColor: "#0C314D",
              marginLeft: "2%",
              marginTop: { xs: "0%", sm: "0%", md: "0%", lg: "2%", xl: "2%" },
              borderRadius: "10px",
              padding: "2%",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: "bold",
                color: "white",
                fontSize: {
                  xs: "15px",
                  sm: "15px",
                  md: "15px",
                  lg: "15px",
                  xl: "20px",
                },
              }}
              variant="h5"
            >
              Enter Your deposit Details
            </Typography>
            <Typography
              sx={{
                textAlign: "start",
                marginTop: "20px",
                fontSize: "18px",
                color: "white",
                fontSize: {
                  xs: "15px",
                  sm: "15px",
                  md: "15px",
                  lg: "15px",
                  xl: "20px",
                },
              }}
              variant="h5"
            >
              Current Account Balance :{" "}
              <span
                style={{
                  color: "green",
                  fontSize: {
                    xs: "15px",
                    sm: "15px",
                    md: "15px",
                    lg: "15px",
                    xl: "20px",
                  },
                }}
              >
                LKR 1000
              </span>
            </Typography>
            <Box
              sx={{
                textAlign: "center",
                marginTop: "20px",
                fontSize: "18px",
                color: "white",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: {
                    xs: "15px",
                    sm: "15px",
                    md: "15px",
                    lg: "15px",
                    xl: "20px",
                  },
                  color: "white",
                }}
                variant="h5"
              >
                min Ammount: LKR 1,000
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: {
                    xs: "15px",
                    sm: "15px",
                    md: "15px",
                    lg: "15px",
                    xl: "20px",
                  },
                  color: "white",
                }}
                variant="h5"
              >
                max Ammount: LKR 30,000
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-around",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: {
                    xs: "120%",
                    sm: "50%",
                    md: "50%",
                    lg: "50%",
                    xl: "50%",
                  },
                }}
              >
                <TextField
                  size="small"
                  variant="standard"
                  placeholder="Enter amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  InputProps={{
                    style: { color: "white" },
                  }}
                  sx={{
                    marginTop: "30px",
                    width: "90%",
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "white",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "white",
                    },
                    "& .MuiInputLabel-root": { color: "white" },
                  }}
                />

                <TextField
                  size="small"
                  variant="standard"
                  placeholder="Enter Transaction ID"
                  value={transactionId}
                  onChange={(e) => setTransactionId(e.target.value)}
                  InputProps={{ style: { color: "white" } }}
                  sx={{
                    marginTop: "30px",
                    width: "90%",
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "white",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "white",
                    },
                    "& .MuiInputLabel-root": { color: "white" },
                  }}
                />

                <Button
                  sx={{
                    marginTop: {
                      xs: "20px",
                      sm: "20px",
                      md: "20px",
                      lg: "20px",
                      xl: "40px",
                    },
                    width: "90%",
                    backgroundColor: "#3A6D8C",
                    color: "white",
                  }}
                  onClick={popup}
                >
                  Deposit
                </Button>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  {/* Hidden file input */}
                  <input
                    id="fileInput"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleImageUpload}
                  />
                </Box>

                {errorMessage && (
                  <Typography color="error">{errorMessage}</Typography>
                )}
                {successMessage && (
                  <Typography color="success">{successMessage}</Typography>
                )}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  width: {
                    xs: "120%",
                    sm: "50%",
                    md: "50%",
                    lg: "50%",
                    xl: "50%",
                  },
                }}
              >
                {/* Avatar that displays the uploaded image */}
                <Avatar
                  src={preview || upload} // default image if no upload yet
                  sx={{
                    width: "80%",
                    height: "50%",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  onClick={handleAvatarClick}
                />
                <Typography
                  sx={{
                    marginTop: {
                      xs: "10px",
                      sm: "10px",
                      md: "10px",
                      lg: "10px",
                      xl: "20px",
                    },
                    fontSize: "18px",
                    color: "white",
                  }}
                  variant="h5"
                >
                  Insert Slipt
                </Typography>
                {/* Hidden file input */}
                <input
                  id="fileInput"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleImageUpload}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "90%",
              height: "40vh",
              marginLeft: "2%",
              marginTop: { xs: "30%", sm: "2%", md: "2%", lg: "2%", xl: "2%" },
              borderRadius: "10px",
              padding: "2%",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: "bold",
                color: "white",
                fontSize: {
                  xs: "15px",
                  sm: "15px",
                  md: "15px",
                  lg: "15px",
                  xl: "20px",
                },
              }}
              variant="h5"
            >
              Bank Details
            </Typography>
            {bankDetails.map((detail, index) => (
              <Box>
                <Typography
                  key={index}
                  sx={{
                    textAlign: "Start",
                    color: "white",
                    marginTop: {
                      xs: "10px",
                      sm: "10px",
                      md: "10px",
                      lg: "10px",
                      xl: "30px",
                    },
                    fontSize: {
                      xs: "15px",
                      sm: "15px",
                      md: "15px",
                      lg: "15px",
                      xl: "20px",
                    },
                  }}
                  variant="h6"
                >
                  Account Number:{" "}
                  <span style={{ color: "green" }}>
                    {detail.Account_number}
                  </span>
                </Typography>
                <Typography
                  key={index}
                  sx={{
                    textAlign: "Start",
                    color: "white",
                    marginTop: {
                      xs: "10px",
                      sm: "10px",
                      md: "10px",
                      lg: "10px",
                      xl: "30px",
                    },
                    fontSize: {
                      xs: "15px",
                      sm: "15px",
                      md: "15px",
                      lg: "15px",
                      xl: "20px",
                    },
                  }}
                  variant="h6"
                >
                  Branch Name :{" "}
                  <span style={{ color: "green" }}>{detail.Branch_name}</span>
                </Typography>
                <Typography
                  key={index}
                  sx={{
                    textAlign: "Start",
                    color: "white",
                    marginTop: {
                      xs: "10px",
                      sm: "10px",
                      md: "10px",
                      lg: "10px",
                      xl: "30px",
                    },
                    fontSize: {
                      xs: "15px",
                      sm: "15px",
                      md: "15px",
                      lg: "15px",
                      xl: "20px",
                    },
                  }}
                  variant="h6"
                >
                  Bank Name :{" "}
                  <span style={{ color: "green" }}>{detail.Bank_name}</span>
                </Typography>
                <Typography
                  key={index}
                  sx={{
                    textAlign: "Start",
                    color: "white",
                    marginTop: {
                      xs: "10px",
                      sm: "10px",
                      md: "10px",
                      lg: "10px",
                      xl: "30px",
                    },
                    fontSize: {
                      xs: "15px",
                      sm: "15px",
                      md: "15px",
                      lg: "15px",
                      xl: "20px",
                    },
                  }}
                  variant="h6"
                >
                  Bank Holder Name :{" "}
                  <span style={{ color: "green" }}>
                    {detail.Bank_Holder_Name}
                  </span>
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            marginTop: { xs: "0%", sm: "0%", md: "0%", lg: "2%", xl: "2%" },
            baackgroundColor: "red",
          }}
        >
          <Box
            sx={{
              width: "90%",
              height: "87vh",
              backgroundColor: "#0C314D",
              marginLeft: "2%",
              borderRadius: "10px",
              padding: "2%",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: "bold",
                color: "white",
                fontSize: {
                  xs: "15px",
                  sm: "15px",
                  md: "15px",
                  lg: "15px",
                  xl: "20px",
                },
              }}
              variant="h5"
            >
              Deposit Transaction Details
            </Typography>
            <TableContainer
              component={Paper}
              sx={{
                marginTop: "20px",
                backgroundColor: "#103958",
                height: "93%",
                overflowY: "auto",
              }}
            >
              <Table sx={{ maxHeight: 50 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        color: "white",
                        fontSize: {
                          xs: "12px",
                          sm: "12px",
                          md: "12px",
                          lg: "10px",
                          xl: "15px",
                        },
                      }}
                      align="center"
                    >
                      Transaction Number
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "white",
                        fontSize: {
                          xs: "12px",
                          sm: "12px",
                          md: "12px",
                          lg: "10px",
                          xl: "15px",
                        },
                      }}
                      align="right"
                    >
                      Date
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "white",
                        fontSize: {
                          xs: "12px",
                          sm: "12px",
                          md: "12px",
                          lg: "10px",
                          xl: "15px",
                        },
                      }}
                      align="right"
                    >
                      Transaction ID
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "white",
                        fontSize: {
                          xs: "12px",
                          sm: "12px",
                          md: "12px",
                          lg: "10px",
                          xl: "15px",
                        },
                      }}
                      align="right"
                    >
                      Amount
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "white",
                        fontSize: {
                          xs: "12px",
                          sm: "12px",
                          md: "12px",
                          lg: "10px",
                          xl: "15px",
                        },
                      }}
                      align="right"
                    >
                      status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {depositDetails && depositDetails.length > 0 ? (
                    depositDetails.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          align="center"
                          sx={{
                            color: "white",
                            fontSize: {
                              xs: "12px",
                              sm: "12px",
                              md: "12px",
                              lg: "10px",
                              xl: "20px",
                            },
                          }}
                          component="th"
                          scope="row"
                        >
                          {row.id}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "white",
                            fontSize: {
                              xs: "12px",
                              sm: "12px",
                              md: "12px",
                              lg: "10px",
                              xl: "15px",
                            },
                          }}
                          align="right"
                        >
                          {new Date(row.created_at).toISOString().split("T")[0]}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "white",
                            fontSize: {
                              xs: "12px",
                              sm: "12px",
                              md: "12px",
                              lg: "10px",
                              xl: "15px",
                            },
                          }}
                          align="right"
                        >
                          {row.transaction_id}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "white",
                            fontSize: {
                              xs: "12px",
                              sm: "12px",
                              md: "12px",
                              lg: "10px",
                              xl: "15px",
                            },
                          }}
                          align="right"
                        >
                          {row.deposit_amount}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "white",
                            fontSize: {
                              xs: "12px",
                              sm: "12px",
                              md: "12px",
                              lg: "10px",
                              xl: "15px",
                            },
                          }}
                          align="right"
                        >
                          {row.status}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={5}
                        align="center"
                        sx={{
                          color: "white",
                          fontSize: {
                            xs: "12px",
                            sm: "12px",
                            md: "12px",
                            lg: "10px",
                            xl: "20px",
                          },
                        }}
                      >
                        No deposit details available.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
