import React from "react";
import { Avatar, Box, Button, TextField, Typography } from "@mui/material";
import upload from "../images/upload.png";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import keta from '../images/Keta.jpg';
import keta1 from '../images/keta1.jpg';

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData('05', 159, 6.0, 24, 4.0),
    createData('04', 237, 9.0, 37, 4.3),
    createData('03', 262, 16.0, 24, 6.0),
    createData('02', 305, 3.7, 67, 4.3),
    createData('01', 356, 16.0, 49, 3.9),
  ];

export default function Withdraw() {
    return (
        <Box sx={{ width: "100%", height: {xs:"140vh" ,sm:"140vh" , md:"140vh" ,lg:"100vh" ,xl:"100vh"}, backgroundColor:"#0C314D",display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Box sx={{ width: "95%", height: "95vh", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: {md:"#0C314D" ,lg:"#103958" ,xl:"#103958"}, borderRadius: "10px", flexDirection:{ xs: "column", md: "column", lg: "row", xl: "row"} }}>
                <Box sx={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center", display: "flex" }}>
                    <Box sx={{ width: "90%", height: {xs:"60%" ,sm:"60%" ,md:"60%" ,lg:"60%" ,xl:"50%"}, backgroundColor:"#0C314D", marginLeft:"2%", marginTop:"2%", borderRadius: "10px", padding:"2%" }}>
                        <Typography sx={{ textAlign: "center", fontWeight: "bold", color: "white", fontSize: {xs:"15px" ,sm:"15px" ,md:"15px" ,lg:"15px" ,xl:"20px"} }} variant="h5">Enter Your Withdraw Details</Typography>
                        <Typography sx={{ textAlign: "start", marginTop: "20px", fontSize: {xs:"15px" ,sm:"15px" ,md:"15px" ,lg:"15px" ,xl:"20px"}, color: "white" }} variant="h5">Current Account Balance : <span style={{color:"green"}}>LKR 1000</span></Typography>
                        <Box sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px", color: "white", display:"flex", flexDirection:"row", justifyContent:"space-around" }}>
                            <Typography sx={{ textAlign: "center", marginTop: {xs:"0px" ,sm:"0px" ,md:"0px" ,lg:"0px" ,xl:"20px"}, fontSize: {xs:"15px" ,sm:"15px" ,md:"15px" ,lg:"15px" ,xl:"20px"}, color: "white" }} variant="h5">min Ammount: LKR 1,000</Typography>
                            <Typography sx={{ textAlign: "center", marginTop: {xs:"0px" ,sm:"0px" ,md:"0px" ,lg:"0px" ,xl:"20px"}, fontSize: {xs:"15px" ,sm:"15px" ,md:"15px" ,lg:"15px" ,xl:"20px"}, color: "white" }} variant="h5">max Ammount: LKR 30,000</Typography>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "row", width:"100%" }}>
                            <Box sx={{ display: "flex", flexDirection: "column", width:"50%" }}>
                            <TextField
                                size="small"
                                variant="standard"
                                placeholder="Enter amount"
                                InputProps={{
                                style: {
                                    color: 'white', // Set the text color to white
                                },
                                }}
                                sx={{
                                marginTop: {xs:"10px" ,sm:"10px" ,md:"10px" ,lg:"20px" ,xl:"30px"},
                                width: "90%",
                                '& .MuiInput-underline:before': {
                                    borderBottomColor: 'white', // Set the underline to white
                                },
                                '& .MuiInput-underline:after': {
                                    borderBottomColor: 'white', // Set the underline after focus to white
                                },
                                '& .MuiInputLabel-root': {
                                    color: 'white', // Set the label color to white (if applicable)
                                },
                                }}
                            />

                            <TextField
                                size="small"
                                variant="standard"
                                placeholder="Enter Account Number"
                                InputProps={{
                                style: {
                                    color: 'white', // Set the text color to white
                                },
                                }}
                                sx={{
                                marginTop: {xs:"10px" ,sm:"10px" ,md:"10px" ,lg:"20px" ,xl:"30px"},
                                width: "90%",
                                '& .MuiInput-underline:before': {
                                    borderBottomColor: 'white', // Set the underline to white
                                },
                                '& .MuiInput-underline:after': {
                                    borderBottomColor: 'white', // Set the underline after focus to white
                                },
                                '& .MuiInputLabel-root': {
                                    color: 'white', // Set the label color to white (if applicable)
                                },
                                }}
                            />

                            <TextField
                                size="small"
                                variant="standard"
                                placeholder="Bank Name"
                                InputProps={{
                                style: {
                                    color: 'white', // Set the text color to white
                                },
                                }}
                                sx={{
                                marginTop: {xs:"10px" ,sm:"10px" ,md:"10px" ,lg:"20px" ,xl:"30px"},
                                width: "90%",
                                '& .MuiInput-underline:before': {
                                    borderBottomColor: 'white', // Set the underline to white
                                },
                                '& .MuiInput-underline:after': {
                                    borderBottomColor: 'white', // Set the underline after focus to white
                                },
                                '& .MuiInputLabel-root': {
                                    color: 'white', // Set the label color to white (if applicable)
                                },
                                }}
                            />
                            <Button  sx={{ marginTop: "20px", width: "90%", backgroundColor: "#3A6D8C", color: "white" }}>Withdraw</Button>
                            </Box>

                            <Box sx={{ display: "flex", alignItems: "center", flexDirection:"column", width:"50%" }}>
                            <TextField
                                size="small"
                                variant="standard"
                                placeholder="Bank Holder Name"
                                InputProps={{
                                style: {
                                    color: 'white', // Set the text color to white
                                },
                                }}
                                sx={{
                                marginTop: {xs:"10px" ,sm:"10px" ,md:"10px" ,lg:"20px" ,xl:"30px"},
                                width: "90%",
                                '& .MuiInput-underline:before': {
                                    borderBottomColor: 'white', // Set the underline to white
                                },
                                '& .MuiInput-underline:after': {
                                    borderBottomColor: 'white', // Set the underline after focus to white
                                },
                                '& .MuiInputLabel-root': {
                                    color: 'white', // Set the label color to white (if applicable)
                                },
                                }}
                            />
                            <TextField
                                size="small"
                                variant="standard"
                                placeholder="Branch"
                                InputProps={{
                                style: {
                                    color: 'white', // Set the text color to white
                                },
                                }}
                                sx={{
                                marginTop: {xs:"10px" ,sm:"10px" ,md:"10px" ,lg:"20px" ,xl:"30px"},
                                width: "90%",
                                '& .MuiInput-underline:before': {
                                    borderBottomColor: 'white', // Set the underline to white
                                },
                                '& .MuiInput-underline:after': {
                                    borderBottomColor: 'white', // Set the underline after focus to white
                                },
                                '& .MuiInputLabel-root': {
                                    color: 'white', // Set the label color to white (if applicable)
                                },
                                }}
                            />
                            </Box>
                            
                        </Box>
                    </Box>
                    {/* <Box sx={{ width: "90%", height: "40vh",  marginLeft:"2%", marginTop:"2%", borderRadius: "10px", padding:"2%" }}>
                        <Typography sx={{ textAlign: "center", fontWeight: "bold", color: "white" }} variant="h5">Most Popular Games</Typography>
                        <Box sx={{display:"flex", flexDirection:"row", marginTop:"40px"}}>
                            <Box sx={{marginTop:"20px", cursor:"pointer", overflow:"hidden",  borderRadius:"10px", color:"black", fontWeight:"bold", width:"50%", height:"300px",  backgroundColor:"white", textAlign:"center", borderRadius:"5px" }}>
                                <Avatar sx={{width:"100%", height:"70%","&:hover": {transform: "scale(1.1)"}, transition: "transform 0.3s ease-in-out", borderRadius:"0px", borderRadius:"5px 5px 0px 0px"}} src={keta} />
                                <Typography sx={{marginTop:"10px",  borderRadius:"0px", color:"black", fontWeight:"bold", color:"black"}} variant="h6">Buruwo</Typography>
                                <Typography sx={{marginTop:"10px",  borderRadius:"0px", color:"black", fontWeight:"bold", color:"black"}} variant="h5">All</Typography>
                            </Box>
                            <Box sx={{marginTop:"20px", marginLeft:"20px", overflow:"hidden", borderRadius:"10px", color:"black", fontWeight:"bold", width:"50%", height:"300px",  backgroundColor:"white", textAlign:"center", borderRadius:"5px" }}>
                                <Avatar sx={{width:"100%", height:"70%", "&:hover": {transform: "scale(1.1)"}, transition: "transform 0.3s ease-in-out", borderRadius:"0px", borderRadius:"5px 5px 0px 0px"}} src={keta1} />
                                <Typography sx={{marginTop:"10px",  borderRadius:"0px", color:"black", fontWeight:"bold", color:"black"}} variant="h6">Keta</Typography>
                                <Typography sx={{marginTop:"10px",  borderRadius:"0px", color:"black", fontWeight:"bold", color:"black"}} variant="h5">All</Typography>
                            </Box>  
                        </Box>
                    </Box> */}
                </Box>
                <Box sx={{ width: "100%", height: "100%" }}>
                    
                    <Box sx={{ width: "90%", height: "87vh", backgroundColor:"#0C314D", marginLeft:"2%", marginTop:{xs:"40%" ,sm:"2%" , md:"2%" ,lg:"2%", xl:"2%"}, marginRight:"2%", marginBottom:"2%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection:"column", backgroundColor: {md:"#0C314D" ,lg:"#103958" ,xl:"#103958"}, borderRadius: "10px", padding:"2%" }}>
                        <Typography sx={{ textAlign: "center", fontWeight: "bold", color: "white" }} variant="h5">Withdraw Transaction Details</Typography>
                        <TableContainer component={Paper} sx={{ marginTop: "20px", backgroundColor:"#103958", height: "93%", overflowY: 'auto' }}>
                            <Table sx={{ maxHeight: 50 }} aria-label="simple table">
                                <TableHead>
                                <TableRow>
                                    <TableCell sx={{ color: "white" }} align="center">Transaction Number</TableCell>
                                    <TableCell sx={{ color: "white" }} align="right">Date</TableCell>
                                    <TableCell sx={{ color: "white" }} align="right">Transaction ID</TableCell>
                                    <TableCell sx={{ color: "white" }} align="right">Amount</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {rows.map((row) => (
                                    <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                    <TableCell align="center" sx={{ color: "white" }} component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell sx={{ color: "white" }} align="right">{row.calories}</TableCell>
                                    <TableCell sx={{ color: "white" }} align="right">{row.fat}</TableCell>
                                    <TableCell sx={{ color: "white" }} align="right">{row.carbs}</TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                            </TableContainer>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}