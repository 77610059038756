import React, { useState, useEffect } from 'react';

import { Container, Grid, Typography, Box, Button } from '@mui/material';
//import BgImage from '../../../public/assets/Bg-image.jpg';
//import Footer from '../../components/Footer/footer';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useNavigate, useParams } from 'react-router-dom';
import './admin.css';
import TransactionDeposits from './transaction_deposits';
import TransactionWithdraw from './transactio_withradraw';

export default function Transaction() {

    const [selectedOption, setSelectedOption] = useState('deposits');

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.id);
      };
    

  return (
    <Box>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          //backgroundImage: `url(${BgImage})`,
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
          backgroundPosition: 'center',
        }}
      >
        
        <Box
          sx={{
            backgroundColor: 'white',
            width: '220%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            padding: '50px',
            borderRadius: '20px',
            marginBottom: '100px',
          }}
        >

          <Box sx={{ width: '200%', marginBottom: '20px', marginTop: '20px', display:"flex", flexDirection:"row" }}>
            <Box sx={{marginRight:"50px"}}>
              <input type="radio" id='deposits' name="transaction" onChange={handleOptionChange}/>
              <label htmlFor="deposits">Deposits</label>
            </Box>
            <Box>
              <input type="radio" id="withdraws" name="transaction" onChange={handleOptionChange}/>
              <label htmlFor="withdraws">Withdraw</label>
            </Box>
          </Box>

          <Box sx={{marginLeft:"-50px", backgroundColor:"white"}}>
            {selectedOption === 'deposits' && <TransactionDeposits />}
            {selectedOption === 'withdraws' && <TransactionWithdraw />}
          </Box>
        </Box>
      </Box>
    </Box>
    
    
  );
}
